<template>

  <div>404</div>
</template>

<script>
export default {
  name: "nopage"
}
</script>

<style scoped>

</style>
